import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  WidgetComponent,
  DatewidgetComponent,
  BarChartIndustryComponent,
  AnalizDashboardChartsComponent,
  TelemWidgetComponent,
  DateComponent,
  MessageComponent,
  ProgressBarByIntervalComponent,
} from "@components";
import { StreetPipe } from "./pipes/street.pipe";
import { DistrictPipe } from "./pipes/district.pipe";
import { SwitchLangComponent } from "./components/switch-lang/switch-lang.component";
import { LoadingModule } from "./components/loading/loading.module";
import { TranslateModule } from "@ngx-translate/core";
import { ToasterContainerComponent } from "./components/toast/toaster-container.component";
import { ToasterComponent } from "./components/toast/toaster.component";
import { NzButtonModule } from "ng-zorro-antd/button";
import { UserPopupMenuComponent } from "./components/user-popup-menu/user-popup-menu.component";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { MapComponent } from "./components/map/map.component";
import { CredentionalDirective, LetDirective } from "./directives/";
import { NgxPrintElementModule } from "ngx-print-element";
import { BarChartVentsComponent } from "./components/bar-chart-vents/bar-chart-vents.component";
import { OnlyFirstTitleCasePipe } from "./pipes/onlyFirstTitleCase.pipe";
import { RouterModule } from "@angular/router";
import { StringFirstCaseToUpperDirectivePipe } from "./pipes/stringFirstCaseToUpperDirective.pipe";
import { SpaceNumberPipe } from "./pipes/spaceNumber.pipe";
import { LineChartComponent } from "./components/line-chart/line-chart.component";
import { TooltipDirective } from "./directives/tooltip.directive";
import { TooltipComponent } from "./directives/tooltip.component";
import { VolumeOneChartComponent } from "./components/volume-one-chart/volume-one-chart.component";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzTagModule } from "ng-zorro-antd/tag";
import { NzCollapseModule } from "ng-zorro-antd/collapse";
import { NzCalendarModule } from "ng-zorro-antd/calendar";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzMessageModule } from "ng-zorro-antd/message";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzSwitchModule } from "ng-zorro-antd/switch";
import { NzInputNumberModule } from "ng-zorro-antd/input-number";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzUploadModule } from "ng-zorro-antd/upload";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzBadgeModule } from "ng-zorro-antd/badge";
import { NzCardModule } from "ng-zorro-antd/card";

import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatNativeDateModule } from "@angular/material/core";
import { VegaTmName } from "./pipes/vegaTmName";
import { NzPaginationModule } from "ng-zorro-antd/pagination";
import { IntersectionScrollComponent } from "./components/intersection-scroll/intersection-scroll.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { BaseChartDirective } from "ng2-charts";
import { MenubarModule } from "primeng/menubar";
import { BadgeModule } from "primeng/badge";
import { ProgressBarModule } from "primeng/progressbar";

// const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
//   suppressScrollX: false,
//   suppressScrollY: false
// };

@NgModule({
  declarations: [
    WidgetComponent,
    DatewidgetComponent,
    BarChartIndustryComponent,
    MessageComponent,
    UserPopupMenuComponent,
    IntersectionScrollComponent,
    AnalizDashboardChartsComponent,
    SwitchLangComponent,
    TelemWidgetComponent,
    StreetPipe,
    DistrictPipe,
    OnlyFirstTitleCasePipe,
    StringFirstCaseToUpperDirectivePipe,
    SpaceNumberPipe,
    VegaTmName,
    ToasterComponent,
    BarChartVentsComponent,
    ToasterContainerComponent,
    MapComponent,
    LineChartComponent,
    CredentionalDirective,
    LetDirective,
    TooltipDirective,
    TooltipComponent,
    DateComponent,
    VolumeOneChartComponent,
    ProgressBarByIntervalComponent,
  ],
  imports: [
    LoadingModule,
    LeafletModule,
    DragDropModule,
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    NzFormModule,
    NzLayoutModule,
    NzTabsModule,
    NgxPrintElementModule,
    NzPaginationModule,
    NzRadioModule,
    NzCardModule,
    MatNativeDateModule,
    NzCollapseModule,
    NzCalendarModule,
    NzInputModule,
    NzBadgeModule,
    NzDropDownModule,
    NzSpinModule,
    NzSwitchModule,
    NzInputNumberModule,
    NzButtonModule,
    NzIconModule,
    NzTagModule,
    NzGridModule,
    NzTableModule,
    NzDividerModule,
    NzMessageModule,
    NzSelectModule,
    NzUploadModule,
    NzPopoverModule,
    NzDatePickerModule,
    NzModalModule,
    NzToolTipModule,
    NzCheckboxModule,
    FormsModule,
    RouterModule,
    MatDatepickerModule,
    MatInputModule,
    BaseChartDirective,
    MenubarModule,
    ProgressBarModule,
    BadgeModule,
  ],
  exports: [
    LoadingModule,
    CommonModule,
    ReactiveFormsModule,
    WidgetComponent,
    DateComponent,
    ToasterComponent,
    MatInputModule,
    NzPaginationModule,
    TooltipComponent,
    TooltipComponent,
    IntersectionScrollComponent,
    ToasterContainerComponent,
    DatewidgetComponent,
    BarChartIndustryComponent,
    AnalizDashboardChartsComponent,
    TelemWidgetComponent,
    DistrictPipe,
    OnlyFirstTitleCasePipe,
    UserPopupMenuComponent,
    LeafletModule,
    TranslateModule,
    VegaTmName,
    LineChartComponent,
    MatNativeDateModule,
    SpaceNumberPipe,
    StreetPipe,
    SwitchLangComponent,
    MessageComponent,
    CredentionalDirective,
    LetDirective,
    BarChartVentsComponent,
    StringFirstCaseToUpperDirectivePipe,
    TooltipDirective,
    NzFormModule,
    MapComponent,
    NzTabsModule,
    NzRadioModule,
    NzPopoverModule,
    NzCollapseModule,
    NzCalendarModule,
    NzInputModule,
    NzDropDownModule,
    NzSpinModule,
    NzCardModule,
    NzSwitchModule,
    NzInputNumberModule,
    NzButtonModule,
    NzBadgeModule,
    NzIconModule,
    NzTagModule,
    NzGridModule,
    NzLayoutModule,
    NzTableModule,
    NzDividerModule,
    NzMessageModule,
    NzSelectModule,
    NzUploadModule,
    NzDatePickerModule,
    NzModalModule,
    NzCheckboxModule,
    NzToolTipModule,
    VolumeOneChartComponent,
    MatDatepickerModule,
    ProgressBarByIntervalComponent,
  ],
})
export class SharedModule {}
