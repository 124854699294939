import { Component, Input, NgZone, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: "pressure-progress-bar-by-interval",
  templateUrl: "./progress-bar-by-interval.component.html",
  styleUrl: "./progress-bar-by-interval.component.scss",
})
export class ProgressBarByIntervalComponent implements OnInit, OnDestroy {
  @Input() public timeSeconds: number = 2000;
  public value: number = 0;
  private interval: any;

  constructor(private ngZone: NgZone) {}

  ngOnInit() {
    this.ngZone.runOutsideAngular(() => {
      this.interval = setInterval(() => {
        this.ngZone.run(() => {
          this.value = this.value + 1;
          if (this.value >= 100) {
            this.value = 100;
            clearInterval(this.interval);
          }
        });
      }, this.timeSeconds / 100);
    });
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
